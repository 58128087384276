:root {
    --background-gradient: repeating-linear-gradient(to bottom right, 
    rgb(184, 182, 182, .3) 10%, white 20%);
    --main-font: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

* {
    margin: 0;
}

.wholePage {
    padding: 20px;
    margin: 15px;
    display: flex;
    justify-content: space-evenly;
}

.aboutImg {
    background-image: url('./Assets/meBridge.jpg');
}

.resumeImg {
    background-image: url('./Assets/hills.jpg');
}

.contactImg {
    background-image: url('./Assets/sign.jpg');
}

.projectImg {
    background-image: url('./Assets/dawnTrees.jpg');
}

.projectLink {
    max-width: 100%;
    max-height: 100%;
}

.imageBanner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 76vh;
}

body {
    /* background-image: var(--background-gradient); */
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* min-height: 100vh;
    min-width: 100vw; */
}

header {
    background-color: black;
    padding: 20px;
    margin: 15px;
    display: flex;
    justify-content: space-evenly;
}

h1 {
    display: inline-block;
    font-size: 30px;
    padding-top: 20px;
    color: white;
    font-family: var(--main-font);
    font-size: 30px;
}

/*Header Cat Image*/
#avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

nav {
    float:right;
    padding-top: 20px;
}

/*Internal links to sections on page*/
.navlinks {
    list-style-type: none;
    display: inline-block;
    margin: 5px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    font-family: var(--main-font);
}

/*remove decoration from all links on page*/
a {
    text-decoration: none;
}

.navlinks a {
    color: white;
}

/* reused for each section chosen from navtabs */
.sectionHeader {
    justify-content: center;
    text-align: center;
    font-family: var(--main-font);
    font-size: 25px;
    margin-top: 30px;
}

.aboutBody {
    margin-top: 10px;
    font-size: 15px;
}

.resumeBody {
    margin-top: 10px;
    font-size: 15px;
    list-style-type: none;
}

#projectIntro {
    font-size: 15px;
    font-style: italic;
    padding-left: 40px;
    padding-right: 40px;
}

.project-text {
    justify-content: center;
    text-align: center;
    font-family: var(--main-font);
    font-size: 20px;
    color: darkblue;
    margin-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
}

/* Next and Prev icons for rotating through projects */
.glyphicon {
    font-size: 40px;
}

.errorMessage {
    font-style: italic;
    color: red;
}

footer {
    text-align: center;
    font-family: var(--main-font);
    padding: 5px;
    padding-top: 15px;
    font-style: italic;
    background-color: black;
    margin: 15px;
    justify-content: space-evenly;
}

.footlinks {
    list-style-type: none;
    color: white;
    font-size: 20px;
    font-family: var(--main-font);
}

.footlinks a {
    color: white;
}

.rowAdjust {
    align-content: center;
}